import React, {Component} from 'react';


class Resume extends Component {

	componentDidMount() {
	  window.scrollTo(0, 0)
	}

	render() {

		return(

			<div className = 'background'>
				<div className = 'portfolioInfo'>
					<div className= 'portfolioPageTitle' >RESUME</div>
					<img className= 'resumeFile' src="https://res.cloudinary.com/drsaojfyp/image/upload/v1544634570/Screen_Shot_2018-12-12_at_12.09.18_PM.png" alt="resume"/>
				</div>	
			</div>	
		)
	}
}

export default Resume;